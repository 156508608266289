
import { Component, Vue } from 'vue-property-decorator'
import { apiPaymentConfigSet, apiPaymentConfigGet } from '@/api/setting'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        MaterialSelect
    }
})
export default class PayMethodEdit extends Vue {
    /** S Data **/

    identity: Number = 0

    status: any = ''

    // 支付配置设置的数据
    paymentConfigData = {
        sub_mch_id: '',
        name: '', //支付名称
        icon: '', //支付图标
        sort: '', //排序
        remark: '', //备注
        merchant_type: 'ordinary_merchant', //（微信支付 ｜｜ 支付宝）商户类型ordinary_merchant-普通商户
        interface_version: 'v2', //微信支付接口版本v2-v2
        mch_id: '', //微信支付商户号
        pay_sign_key: '', //微信商户支付API密钥
        apiclient_cert: '', //微信支付证书
        apiclient_key: '', //微信支付证书密钥
        pattern: '', //模式：normal_mode普通商户
        app_id: '', //应用ID
        private_key: '', //支付宝公钥
        ali_public_key: '', //应用私钥
        pay_way: '',
        wechat_public_serial: '', //公钥id
        wechat_public_cert: '' //公钥
    }

    // 表单验证
    rules: any = {
        name: [{ required: true, message: '请输入显示名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请输入上传图标', trigger: 'change' }],
        mch_id: [
            {
                required: true,
                message: '请输入微信支付商户号',
                trigger: 'blur'
            }
        ],
        pay_sign_key: [
            {
                required: true,
                message: '请输入微信商户支付API密钥',
                trigger: 'blur'
            }
        ],
        apiclient_cert: [{ required: true, message: '请输入微信支付证书', trigger: 'blur' }],
        apiclient_key: [
            {
                required: true,
                message: '请输入微信支付证书密钥',
                trigger: 'blur'
            }
        ],
        private_key: [{ required: true, message: '请输入支付宝公钥', trigger: 'blur' }],
        ali_public_key: [{ required: true, message: '请输入应用私钥', trigger: 'blur' }],
        app_id: [{ required: true, message: '请输入应用ID', trigger: 'blur' }],
        sort: [
            { required: true, message: '请输入排序', trigger: 'blur' },
            {
                type: 'number',
                pattern: !/-|\+|(\.[0-9])/,
                message: '请输入正确的排序',
                trigger: 'blur'
            }
        ]
    }

    /** E Data **/

    /** S Methods **/

    // 获取支付方式数据
    getPaymentConfigDetail() {
        apiPaymentConfigGet({
            id: this.identity
        })
            .then((res: any) => {
                // 解构出结果的
                const result = {
                    ...res.config,
                    ...res
                }

                delete result.config

                // if (result.pay_way == 2) {
                //     result.interface_version = 'v2'
                // }
                if (result.pay_way == 3) {
                    result.mode = 'normal_mode'
                }
                // result.merchant_type = 'ordinary_merchant'

                this.paymentConfigData = result
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            if ((this.paymentConfigData as any).sort <= 0) {
                return this.$message.error('请输入正整数')
            }
            this.handlePayConfigEdit()
        })
    }

    // 编辑支付配置
    handlePayConfigEdit() {
        const params = this.paymentConfigData
        const id: number = this.identity as number
        apiPaymentConfigSet({ ...params, id })
            .then(() => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('保存失败!')
            })
    }

    // 复制域名
    onCopy() {
        const createInput = document.createElement('input')
        createInput.value = 'http://xxx.com/pay/'
        document.body.appendChild(createInput)
        createInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        createInput.style.display = 'none'
        this.$message({ message: '复制成功', type: 'success' })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.identity = query.id
        this.getPaymentConfigDetail()
    }
    /** E Life Cycle **/

    /** S Compute Attr **/

    /** E Compute Attr **/
}
